import { useRouter } from 'next/router';
import { func, string } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState, useContext } from 'react';

import { getToken } from '#api/user';
import { event } from '#helpers/gtag';
import { TextField, Typography } from '#atoms/index';
import { Button, Paragraph } from '#styleguide/index';

import GlobalContext from '../../context/Global/GlobalContext';

const Login = ({ onClickRecovery }) => {
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    formState: { isSubmitting }
  } = useForm();
  const { isLoginUser } = useContext(GlobalContext);
  const router = useRouter();
  const [isWrongCreds, setIsWrongCreds] = useState(false);

  const parseLowerCase = event => setValue('email', event.target.value.toLowerCase());

  const onSubmit = async data => {
    setIsWrongCreds(false);

    try {
      const response = await getToken({
        username: data.email,
        password: data.password,
        platform: 'zona-padres'
      });
      window.localStorage.setItem('accessToken', response.data.access);
      window.localStorage.setItem('refreshToken', response.data.refresh);
      window.localStorage.setItem('platform', 'zona-padres');
      isLoginUser(true);
      //google event
      if (response?.data?.ff_select_profile === true) {
        event('access-login', 'form-login', 'clic en botón ingresar', '2');
        event(
          `login usuario: ${data.email}`,
          'form-login',
          'clic en botón ingresar',
          '2'
        );
        router.push('/login-perfiles');
      } else {
        router.push('/dashboard');
      }
    } catch {
      setIsWrongCreds(true);
    }
  };

  return (
    <section>
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          onChange={parseLowerCase}
          placeholder="Usuario"
          register={register({
            required: '¡No tan rápido!, te has olvidado de escribir tu email',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Esto no es un email, intenta con uno válido'
            }
          })}
          error={!!errors.email}
        />
        {errors.email && (
          <div style={{ marginTop: '-0.5rem' }}>
            <Typography variant="body2" color="red">
              {errors.email.message}
            </Typography>
          </div>
        )}
        <TextField
          name="password"
          placeholder="Contraseña"
          type="password"
          register={register({required: '¡No tan rápido!, te has olvidado de escribir tu contraseña'})}
          error={!!errors.password}
        />
        {errors.password && (
          <div>
            <Typography variant="body2" color="red">
              {errors.password.message}
            </Typography>
          </div>
        )}
        {isWrongCreds && (
          <Typography variant="body2" color="red">
            Email o contraseña incorrecta
          </Typography>
        )}
        <div style={{ background: '#F1F1F1', padding: '17px 26px', borderRadius: '8px' }}>
          <Paragraph size="14">
            <b>¡Recuerda!</b> Enviamos tu usuario y contraseña a tu correo cuando
            inscribiste a tu hijo o hija.
          </Paragraph>
        </div>
        <Button type="submit" disabled={isSubmitting}>
          {!isSubmitting ? 'Ingresar' : 'Ingresando...'}
        </Button>

        <Button level="linear" size="small" onClick={onClickRecovery}>
          ¿Olvidaste tu contraseña?
        </Button>
      </form>
      {/* <Button variant="link" onClick={onClickRecovery}>
        Olvidé mi contraseña
      </Button> */}
    </section>
  );
};

Login.propTypes = {
  onClickRegister: func,
  onClickRecovery: func,
  variant: string
};

export default Login;
